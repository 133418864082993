<template>
  <p
    class="mt-3 mb-0"
  >
    <span
      class="otp-refresh-link my-3 btn btn-outline-dark"
      @click="requestNewOTP"
      >
        Nieuwe code verzenden
      <span
        v-if="secondsSinceLastOtpRequest !== null"
      >
        ({{ otpRequestIntervalSeconds - secondsSinceLastOtpRequest }})
      </span>
    </span>
  </p>
</template>

<script>
import { requestOTP } from '@/api/providers/account';

export default {
  data() {
    return {
      secondsSinceLastOtpRequest: null,
      otpRequestIntervalSeconds: 30
    }
  },

  watch: {
    secondsSinceLastOtpRequest: {
        handler(value) {
          if (value === null) return;

          if (value <= this.otpRequestIntervalSeconds) {
            setTimeout(() => {
                this.secondsSinceLastOtpRequest++;
            }, 1000);
            
            return;
          }

          this.secondsSinceLastOtpRequest = null;
      },
      immediate: true
    }
  },

  methods: {
    requestNewOTP() {
      if (this.secondsSinceLastOtpRequest === null) {
        requestOTP(this.$route.params.username);
        this.secondsSinceLastOtpRequest = 0;
      }
    },
  },
}
</script>

<style scoped>
p {
  text-align: center;
}

.otp-refresh-link {
  font-weight: 500;

  &:hover {
    cursor: pointer;
    color: #ec407a;
    border-color: #ec407a
  }
}
</style>