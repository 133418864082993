<template>
  <form
    role="form"
    class="text-start mt-3"
    @submit.prevent="submit"
  >
    <p class="mt-4 text-sm">
      Er is een eenmalige inlog-code verzonden naar <strong>{{$route.params.username}}</strong>.
      Vul deze hieronder in om in te loggen.
    </p>
    <material-alert
      v-show="error.length"
      color="danger"
    >
      {{error}}
    </material-alert>
    <material-input
      id="password"
      v-model="password"
      :disabled="loading"
      label="Eenmalige code"
      name="password"
      is-required
    />

    <material-button
      class="my-4"
      variant="gradient"
      color="primary"
      full-width
    >
      Inloggen
    </material-button>

    <otp-refresh-button 
      v-if="error !== ''"
    />
  </form>
</template>

<script>
import { mapActions } from 'vuex';
import { validateOTP } from '@/api/providers/account';
import MaterialAlert from "@/components/MaterialAlert.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import OtpRefreshButton from '../../../components/Account/OtpRefreshButton.vue';

export default {
  name: "LoginPassword",

  components: {
    MaterialAlert,
    MaterialButton,
    MaterialInput,
    OtpRefreshButton
  },

  data: () => ({
    error: '',
    loading: false,
    password: '',
  }),

  methods: {
    ...mapActions('account', [
      'setAuth',
      'setOrganizations',
    ]),

    submit() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      validateOTP(this.$route.params.username, this.password)
        .then(({ auth, organizations }) => {
          this.setAuth(auth);
          this.setOrganizations(organizations);

          this.$router.push({
            name: 'account-organisations',
          });
        })
        .catch(({ message }) => {
          this.password = '';
          this.error = message;
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>
